$(
	function() {
		app.assignProductOver();
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.setBackUrls();
		app.saveProductListInfo();
		app.createPrevNextButtons();
        app.hamburger.init();
        if (app._vars._prodDetailsSpecialProcessing === 1) {
			app.assignSpecialProcessing1();
			app.doSpecialProcessing1(true);
		}
        $('tr[data-udu-id=Inscription]').addClass('_done');
	}
);

var app = {
	_vars: {
		_guid: 'a428a21c-b91c-410f-8467-77930ee48786'
	},
	_VERSION: 2,
	vv: 2,
	treeOptions: {
		colorizedNode: true
	},
    doSpecialProcessing1: function(noFlick) {
        var $this = $('select[data-sys-name=inscription_length]');
        if ($this.length === 0) {
        	return false;
		}
        var $op = $this.find('option:selected');
        var ln = parseInt($op.text());
        if (!ln) {
        	ln = 255;
		}
		var $inp = $('tr[data-udu-id=Inscription] input');
        $inp.attr('style', 'text-align:center;width: ' + (Math.min(20 + (ln) * 7, 120)) + 'px !important').attr('maxlength', ln);
		$inp.val($inp.val().substr(0, ln));
		var strLen = $inp.val().length;
		var $span = $('span.udu__insp-info');

		if (ln === 255) {
            $span.html('');
		} else {
            $span.html(app.lang.selectWord('Required length: ' + ln, 'Longueur requise: ' + ln));
		}

		if (strLen === ln || ln === 255) {
            $span.removeClass('udu__error');
		} else {
            $span.addClass('udu__error');
            if (!noFlick) {
                app.doFlicker($inp);
			}
		}
	},
	assignSpecialProcessing1: function() {
		if (!app.prodInfo) {
			return false;
		}

		var $item = $('select[data-sys-name=inscription_length]');
		if ($item.length === 0) {
			return false;
		}
        $('tr[data-udu-id=Inscription] td.value').append('<span class="udu__insp-info"></span>');
		$item.change(
			function() {
				app.doSpecialProcessing1();
			}
		);

        var $inp = $('tr[data-udu-id=Inscription] input');
		$inp.keyup(
			function() {
				app.doSpecialProcessing1(true);
			}
		);

        app.events.addListener(
        	'udu_ON-PROD-FORM-SUBMIT',
			function(e) {
                var $span = $('span.udu__insp-info');
                var $inp = $('tr[data-udu-id=Inscription] input');
                if ($span.hasClass('udu__error')) {
                	app.alertMsg('udu_Insp_Required_Length', null, {replace: {'%LENGTH%': $inp.attr('maxlength')}});
                    e.stopGo = true;
				}
        	}
        );
	}
};